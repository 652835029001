import { useQuery } from "@tanstack/react-query"
import debounce from "debounce"
import ChevronsUpDownIcon from "lucide-static/icons/chevrons-up-down.svg"
import { useEffect, useState } from "react"
import type { ControllerRenderProps } from "react-hook-form"
import InlineSVG from "react-inlinesvg"

import {
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  FormMessage,
  Popover,
  PopoverContentCombobox,
  PopoverTrigger,
} from "@fourel/ui"

import type { AirportType } from "#components/quotations/create-quote/models/trip-types.js"
import { QueryKeys } from "#constants/query-keys.js"
import { graphql } from "#gql"
import { client } from "#graphql-client"

import type { QuoteFormType } from "../create-quote.js"

const AirportByNameDocument = graphql(/* GraphQL */ `
  query AirportByName($input: QueryAirportByNameInput!) {
    airportByName(input: $input) {
      id
      name
      icaoCode
      iataCode
    }
  }
`)

interface AirportSelectProps {
  field:
    | ControllerRenderProps<QuoteFormType, `legs.${number}.departureAirport`>
    | ControllerRenderProps<QuoteFormType, `legs.${number}.arrivalAirport`>
  airportField: `legs.${number}.departureAirport` | `legs.${number}.arrivalAirport`
  placeholder: string
  setValue: (
    field: `legs.${number}.departureAirport` | `legs.${number}.arrivalAirport`,
    value: { id: string; name: string; code: string },
  ) => void
}

export const AirportSelect = ({
  field,
  airportField,
  placeholder,
  setValue,
}: AirportSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [airportSearchParam, setAirportSearchParam] = useState<string>("")
  const [queryData, setQueryData] = useState<AirportType[]>([])
  const DEBOUNCE_TIME = 500

  const { data } = useQuery({
    queryKey: [QueryKeys.Airport.GetByName, { airportSearchParam }],
    queryFn: () =>
      client.request(AirportByNameDocument, {
        input: { airportName: airportSearchParam },
      }),
    enabled: !!airportSearchParam,
  })

  useEffect(() => {
    setQueryData((data?.airportByName as AirportType[]) ?? [])
  }, [data])

  const handleInputChange = debounce((value) => {
    if (value.length > 2) {
      setAirportSearchParam(value)
    } else {
      setAirportSearchParam("")
      setQueryData([])
    }
  }, DEBOUNCE_TIME)

  const airportSelectHandler = (item: AirportType) => {
    setValue(airportField, {
      id: item.id,
      code: item.iataCode || item.icaoCode,
      name: item.name,
    })
    setIsOpen(false)
  }

  return (
    <FormItem className="w-full">
      <div className="flex items-center justify-between gap-2">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                role="combobox"
                className={cn(
                  "w-full cursor-pointer justify-between text-xs font-medium text-slate-900",
                  !field.value.name && "text-muted-foreground",
                )}
              >
                {field.value.name ? (
                  <div className="flex w-full justify-between">
                    <p>{field.value.name}</p>
                    <InlineSVG
                      src={ChevronsUpDownIcon}
                      className="ml-2 size-4 shrink-0 opacity-50"
                    />
                  </div>
                ) : (
                  <p>{placeholder}</p>
                )}
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContentCombobox className="p-0">
            <Command>
              <CommandInput
                placeholder="Search Airport"
                className="placeholder:text-xs"
                onValueChange={handleInputChange}
              />
              <CommandEmpty>No airport found.</CommandEmpty>
              <CommandList className="max-h-60">
                {queryData.length !== 0 &&
                  queryData.map((item) => (
                    <CommandItem
                      value={item.name}
                      key={item.id}
                      onSelect={() => airportSelectHandler(item)}
                      className="cursor-pointer text-xs"
                    >
                      {item.name} - {item.icaoCode || item.iataCode}
                    </CommandItem>
                  ))}
              </CommandList>
            </Command>
          </PopoverContentCombobox>
        </Popover>
      </div>
      <FormMessage className="text-xs" />
    </FormItem>
  )
}
